import "./../style/BoxC.css"
import React from 'react'

function BoxC(){

    const handleClick = () => {
        alert('서비스 준비 중입니다. 3개월 후에 정식 서비스로 돌아옵니다.');
    };

    return(
        <div>
            <div className="box-c-top-container">
                <div>
                    <div>바쁘셔서 신경쓰시는 것이 어려우신가요?</div>
                    <div>저렴한 비용으로, 셀프애드에 맡기는 방법도 가능합니다.</div>
                </div>
                <div>
                    <button className="box-c-top-button" onClick={handleClick}>문의하기</button>
                </div>
            </div>
            <div  id="intro"  className="box-c-bot-container">(주)카보는 광고대행사가 아닌, 광고중개사입니다.
            </div>
        </div>
    )
}

export default BoxC;