import "./../style/Footer.css"
import { ReactComponent as Footimg } from "./../imgs/footer-logo.svg"

function Footer(){
    return(
        <div className="footer-container">
            <div className="footer-left-box">
                <div>상호: (주)카보</div>
                <div>대표: 이동진</div>
                <div>주소: 충청남도 계룡시 장안1길 6, 202호</div>
                <div>사업자번호: 291-86-02840</div>
            </div>
            <div>
                <Footimg width="calc(130 * (100vw/1920))" height="calc(100 * (100vw / 1920))"></Footimg>
            </div>
            <div className="footer-right-box">
                <div>영업 시간: 평일 09:00 ~ 18:00</div>
                <div>대표 전화: 044-715-7442</div>
                <div>copyright 카보. All right Reserved</div>
                <div>개인정보책임관리자: 이동진</div>
            </div>

        </div>
    )
}

export default Footer;