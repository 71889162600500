import "./../style/MoBoxD.css"
import { ReactComponent as EimgE } from "./../imgs/boxe-img-e.svg"

function MoBoxD(){
    return(
        <div className="mo-boxd-container">
            <div className="mo-boxd-top-text">문의하기</div>
            <div className="mo-boxd-middle-text">업무 협약, 계약, 서비스 문의 등</div>
            <div className="mo-boxd-middle-text">요청사항을 남겨주세요.</div>
            <div className="mo-boxd-middle-text">확인 후 답변드립니다.</div>
            <div className="mo-boxd-foot-box">
                <EimgE></EimgE>
                <div className="mo-boxd-bot-text">ceo@cabo.kr</div>
            </div>
            

        </div>
    )
}

export default MoBoxD;