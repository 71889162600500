import "./../style/MoBoxE.css"

function MoBoxE(){
    return(
        <div>

        </div>
    )
}

export default MoBoxE;