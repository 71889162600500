import "./../style/BoxE.css"
import { ReactComponent as EimgA } from "./../imgs/boxe-img-a.svg"
import { ReactComponent as EimgB } from "./../imgs/boxe-img-b.svg"
import { ReactComponent as EimgC } from "./../imgs/boxe-img-c.svg"
import { ReactComponent as EimgD } from "./../imgs/boxe-img-d.svg"
import { ReactComponent as EimgE } from "./../imgs/boxe-img-e.svg"


function BoxE(){
    return(
        <div>
            
            <div className="box-e-top-container">
                <div className="box-e-top-text">
                    <div className="box-e-top-main">Vision of CABO</div>
                    <div className="box-e-top-sub">광고주, 인플루언서와 함께 성장하는 기업, 주식회사 카보</div>
                </div>
                <div className="box-e-top-contents">
                    <div className="box-e-top-items">
                        <div className="box-e-top-img">
                            <EimgA width="calc(150 * (100vw/1920))" height="calc(150 * (100vw / 1920))"></EimgA>
                        </div>
                        <div>data 가공을 통해</div>
                        <div>정보 비대칭을 해결합니다.</div>
                    </div>
                    <div className="box-e-top-items">
                        <div className="box-e-top-img">
                            <EimgB width="calc(150 * (100vw/1920))" height="calc(150 * (100vw / 1920))"></EimgB>
                        </div>
                        <div>광고주, 인플루언서의 실질적인</div>
                        <div>이익을 목표로합니다.</div>
                    </div>
                    <div className="box-e-top-items">
                        <div className="box-e-top-img">
                            <EimgC width="calc(150 * (100vw/1920))" height="calc(150 * (100vw / 1920))"></EimgC>
                        </div>
                        <div>인플루언서, 광고주와</div>
                        <div>함께 성장합니다.</div>
                    </div>
                    <div className="box-e-top-items">
                        <div className="box-e-top-img">
                            <EimgD width="calc(150 * (100vw/1920))" height="calc(150 * (100vw / 1920))"></EimgD>
                        </div>
                        <div>Data 기술로 시장을 혁신하는</div>
                        <div>광고 플랫폼 스타트업입니다.</div>
                    </div>
                </div>

            </div>

            <div id="contact" className="box-e-bot-container">
                <div className="box-e-bot-maintext">문의하기</div>
                <div className="box-e-bot-subtext">업무 협약, 계약, 서비스 문의 등 요청사항을 남겨주세요.</div>
                <div className="box-e-bot-subtext">확인 후 답변 드립니다.</div>
                <div className="box-e-bot-footbox">
                    <EimgE></EimgE>
                    <div className="box-e-bot-foottext">ceo@cabo.kr</div>
                </div>
            </div>

        </div>
    )
}

export default BoxE;