import "./../style/BoxA.css"

function BoxA(){
    return(
        <div id="service" className="box-a-container">
                <div>바이럴 광고 프로젝트,</div>
                <div className="blue-text"> "직접" </div>
                <div>진행하고 비용을 절감해보세요.</div>
        </div>
    )
}

export default BoxA;