import "./../style/BoxD.css"
import { ReactComponent as DimgA } from "./../imgs/boxd-img-a.svg"
import { ReactComponent as DimgB } from "./../imgs/boxd-img-b.svg"
import { ReactComponent as DimgC } from "./../imgs/boxd-img-c.svg"
import React from 'react'


function BoxD(){

    const handleClick = () => {
        alert('서비스 준비 중입니다. 3개월 후에 정식 서비스로 돌아옵니다.');
    };

    return(
        <div className="box-d-container">
            <div className="box-d-left-box">
                <div className="box-d-left-contents">
                    <div>
                        <DimgA width="calc(120 * (100vw/1920))" height="calc(120 * (100vw / 1920))"></DimgA>
                    </div>
                    <div>
                        <div className="boxd-left-top-text">설립 일자</div>
                        <div className="boxd-left-bot-text">2023.03.03</div>
                    </div>
                </div>
                <div className="box-d-left-contents">
                    <div>
                        <DimgB width="calc(120 * (100vw/1920))" height="calc(120 * (100vw / 1920))"></DimgB>
                    </div>
                    <div>
                        <div className="boxd-left-top-text">소재지</div>
                        <div className="boxd-left-bot-text">충청남도 계룡시</div>
                    </div>
                </div>
                <div className="box-d-left-contents">
                    <div>
                        <DimgC width="calc(120 * (100vw/1920))" height="calc(120 * (100vw / 1920))"></DimgC>
                    </div>
                    <div>
                        <div className="boxd-left-top-text">주요 사업</div>
                        <div className="boxd-left-bot-text">셀프 애드</div>
                    </div>
                </div>
            </div>

            <div className="box-d-right-box">
                <div className="boxd-right-top-text">About CABO</div>
                <div className="boxd-right-middle-text">
                    <div>주식회사 카보는 기존의 바이럴 광고 시장에서</div>
                    <div>광고대행사와 소규모 광고주간의 불합리한 정보비대칭을</div>
                    <div>해결합니다.</div>
                    <div className="line-jump"></div>
                    <div>소규모 사업자에게도 광고 정보 접근성을 높여주고 직접 프로젝트를</div>
                    <div>진행할 수 있도록 보조하는 서비스를 개발해왔습니다.</div>
                    <div className="line-jump"></div>
                    <div>불합리한 광고 수수료를 지불할 필요 없이, 작은 금액으로도 바이럴</div>
                    <div>광고를 진행 할 수 있는 서비스를 확인해 보세요.</div>
                </div>
                <button className="boxd-right-button" onClick={handleClick}>셀프 애드 이용하기</button>
            </div>
        </div>
    )
}

export default BoxD;