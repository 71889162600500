import "./../style/BoxB.css"
import { ReactComponent as PhoneA } from "./../imgs/phone-a.svg"
import { ReactComponent as PhoneB } from "./../imgs/phone-b.svg"

function BoxB(){
    return(
        <div>
            <div className="box-b-top-container">
                <div className="box-b-text-top">
                    <div className="box-b-text-top-main">인플루언서 광고 계약 & 관리 보조서비스 셀프애드</div>
                    <div className="box-b-text-top-sub">인플루언서 계약부터,</div>
                    <div className="box-b-text-top-sub">광고 관리까지 직접 할 수 있다?</div>
                    <div className="box-b-text-top-sub">비용은 절감하고, 광고는 스마트하게!</div>
                </div>
                <div className="box-b-img-top">
                    <PhoneA />
                </div>
            </div>

            <div className="box-b-bot-container">
                <div className="box-b-img-bot">
                    <PhoneB />
                </div>
                <div className="box-b-text-bot">
                    <div>직접 인플루언서와 계약이 가능합니다.</div>
                    <div>계약 보조와 지표관리는 샐프애드 프로그램이 도와드려요.</div>
                    <div>장소(음식점), 제품(제조 유통업체), 서비스(스타트업) 등</div>
                    <div>모든 종류를 홍보할 수 있어요.</div>
                    <div>광고 대행. 맡기지만 마시고, 스마트하게 관리해보세요.</div>
                    <div>샐프애드가 도와드립니다.</div>
                </div>
            </div>
        </div>
    )
}

export default BoxB;