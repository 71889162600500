import "./../style/MoBoxB.css"

function MoBoxB(){
    return(
        <div className="mo-boxb-container">
            <div className="mo-boxb-maintext">About CABO</div>
            <div className="mo-boxb-subtext">주식회사 카보는 기존의 바이럴 광고 시장에서 광고사와 소규모 광고주간의 불합리한 정보비대칭을 해결합니다.</div>
            <div className="mo-boxb-subtext">소규모 사업자에게도 광고 정보접근성을 높여주고 직접 프로젝트를 진행할 수 있도록 보조하는 서비스를 개발해왔습니다.</div>
            <div className="mo-boxb-subtext">불합리한 광고 수수료를 지불할 필요 없이, 작은 금액으로도 바이럴 광고를 진행 할 수 있는 서비스를 확인해보세요.</div>
        </div>
    )
}

export default MoBoxB;