import "./../style/MoFooter.css"
import { ReactComponent as Footimg } from "./../imgs/footer-logo.svg"

function MoFooter(){
    return(
        <div className="mo-footer-container">
            <div className="mo-footer-img">
                <Footimg width="calc(39 * (100vw/320))" height="calc(30 * (100vw / 320))"></Footimg>
            </div>
            <div className="mo-footer-text">상호: (주)카보</div>
            <div className="mo-footer-text">대표: 이동진</div>
            <div className="mo-footer-text">주소: 충청남도 계룡시 장안 1길 6, 202호</div>
            <div className="mo-footer-text">사업자 번호: 291-86-02840</div>
            <div className="mo-footer-text">대표전화: 044-715-7442</div>
            <div className="mo-footer-text">영업시간: 평일 09:00 ~ 18:00</div>
            <div className="mo-footer-text">copyright 카보. All right Reserved</div>
            <div className="mo-footer-text">개인정보책임관리자: 이동진</div>
        </div>
    )
}

export default MoFooter;