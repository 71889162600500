import React, { useState, useEffect } from 'react'

import "./style/reset.css";
import Header from "./components/Header";
import MoHeader from './components/MoHeader';
import BoxA from './components/BoxA';
import MoBoxA from './components/MoBoxA';
import BoxB from './components/BoxB';
import MoBoxB from './components/MoBoxB';
import BoxC from './components/BoxC';
import MoBoxC from './components/MoBoxC';
import BoxD from './components/BoxD';
import MoBoxD from './components/MoBoxD';
import BoxE from './components/BoxE';
import MoBoxE from './components/MoBoxE';
import Footer from './components/Footer';
import MoFooter from './components/MoFooter';

function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 920);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 920);
      };

      // resize 이벤트 리스너 추가
      window.addEventListener('resize', handleResize);

      // 컴포넌트 언마운트 시 리스너 제거
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div>
        { isMobile ? <MoHeader></MoHeader> : <Header></Header> }
        { isMobile ? <MoBoxA></MoBoxA> : <BoxA></BoxA> }
        { isMobile ? <MoBoxB></MoBoxB> : <BoxB></BoxB> }
        { isMobile ? <MoBoxC></MoBoxC> : <BoxC></BoxC> }
        { isMobile ? <MoBoxD></MoBoxD> : <BoxD></BoxD> }
        { isMobile ? <MoBoxE></MoBoxE> : <BoxE></BoxE> }
        { isMobile ? <MoFooter></MoFooter> : <Footer></Footer> }

      
    </div>
  );
}

export default App;
