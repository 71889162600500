import "./../style/Header.css"
import { ReactComponent as CaboLogo } from "./../imgs/cabo-logo.svg"
import React from 'react'

function Header(){

    const handleClick = () => {
        alert('서비스 준비 중입니다. 3개월 후에 정식 서비스로 돌아옵니다.');
    };

    return(
        <div className="main-container">

            <div className="header-container">
                <div className="header-left-box">
                    <CaboLogo width="calc(65 * (100vw/1920))" height="calc(50 * (100vw / 1920))"/>
                </div>
                <div className="header-right-box">
                    <a href="#intro" className="hrb-a">회사 소개</a>
                    <a href="#service" className="hrb-b">서비스 소개</a>
                    <a href="#contact" className="hrb-c">Contact Us</a>
                </div>
            </div>

            <div className="main-text-box">
                <div className="main-text">의미 있는 홍보가 필요하신가요?</div>
                <div className="main-text">셀프 바이럴 광고 솔루션 [셀프애드]</div>
            </div>
            <button className="main-button" onClick={handleClick}>서비스 page 이동하기</button>


        </div>
    )
}

export default Header;