import "./../style/MoHeader.css"
import { ReactComponent as CaboLogo } from "./../imgs/cabo-logo.svg"
import React from 'react'

function MoHeader(){

    const handleClick = () => {
        alert('서비스 준비 중입니다. 3개월 후에 정식 서비스로 돌아옵니다.');
    };


    return(
        <div className="mo-main-container">
            <div className="mo-header-container">
                <div>
                    <CaboLogo width="calc(52 * (100vw/320))" height="calc(40 * (100vw / 320))"></CaboLogo>
                </div>
                <div className="mo-header-text">(주)카보</div>
            </div>
            <div className="mo-main-text">
                <div>의미 있는 홍보가</div>
                <div>필요하신가요?</div>
                <div>셀프 바이럴 광고 솔루션</div>
                <div>[셀프 애드]</div>
            </div>
            <button className="mo-main-button" onClick={handleClick}>서비스 page 이동</button>

        </div>
    )
}

export default MoHeader;