import "./../style/MoBoxA.css"
import { ReactComponent as DimgA } from "./../imgs/boxd-img-a.svg"
import { ReactComponent as DimgB } from "./../imgs/boxd-img-b.svg"
import { ReactComponent as DimgC } from "./../imgs/boxd-img-c.svg"

function MoBoxA(){
    return(
        <div className="mo-boxa-container">
                <div className="mo-boxa-contents">
                    <div>
                        <DimgA width="calc(60* (100vw/320))" height="calc(60 * (100vw/320))"></DimgA>
                    </div>
                    <div>
                        <div className="mo-boxa-top-text">설립 일자</div>
                        <div className="mo-boxa-bot-text">2023.03.03</div>
                    </div>
                </div>
                <div className="mo-boxa-contents">
                    <div>
                        <DimgB width="calc(60* (100vw/320))" height="calc(60* (100vw/320))"></DimgB>
                    </div>
                    <div>
                        <div className="mo-boxa-top-text">소재지</div>
                        <div className="mo-boxa-bot-text">충청남도 계룡시</div>
                    </div>
                </div>
                <div className="mo-boxa-contents">
                    <div>
                        <DimgC width="calc(60* (100vw/320))" height="calc(60* (100vw /320))"></DimgC>
                    </div>
                    <div>
                        <div className="mo-boxa-top-text">주요 사업</div>
                        <div className="mo-boxa-bot-text">셀프 애드</div>
                    </div>
                </div>    
        </div>
    )
}

export default MoBoxA;