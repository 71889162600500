import "./../style/MoBoxC.css"
import { ReactComponent as EimgA } from "./../imgs/boxe-img-a.svg"
import { ReactComponent as EimgB } from "./../imgs/boxe-img-b.svg"
import { ReactComponent as EimgC } from "./../imgs/boxe-img-c.svg"
import { ReactComponent as EimgD } from "./../imgs/boxe-img-d.svg"

function MoBoxC(){
    return(
        <div className="mo-boxc-container">
            <div className="mo-boxc-maintext">Vision of CABO</div>
            <div className="mo-boxc-subtext">광고주, 인플루언서와 함께</div>
            <div className="mo-boxc-subtext">성장하는 기업. 카보</div>
            <div className="mo-boxc-contents">
                <div className="mo-boxc-items">
                    <EimgA width="calc(100 * (100vw/320))" height="calc(100 * (100vw / 320))"></EimgA>
                </div>
                <div>data 가공을 통해</div>
                <div>정보 비대칭을 해결합니다.</div>
            </div>

            <div className="mo-boxc-contents">
                <div className="mo-boxc-items">
                    <EimgB width="calc(100 * (100vw/320))" height="calc(100 * (100vw / 320))"></EimgB>
                </div>
                <div>광고주, 인플루언서의 실질적인</div>
                <div>이익을 목표로합니다.</div>
            </div>

            <div className="mo-boxc-contents">
                <div className="mo-boxc-items">
                    <EimgC width="calc(100 * (100vw/320))" height="calc(100 * (100vw / 320))"></EimgC>
                </div>
                <div>인플루언서, 광고주와</div>
                <div>함께 성장합니다.</div>
            </div>

            <div className="mo-boxc-contents">
                <div className="mo-boxc-items">
                    <EimgD width="calc(100 * (100vw/320))" height="calc(100 * (100vw / 320))"></EimgD>
                </div>
                <div>Data 기술로 시장을 혁신하는</div>
                <div>광고 플랫폼 스타트업입니다.</div>
            </div>

        </div>
    )
}

export default MoBoxC;